/* 
 *  - File: FormContext.js
 *  - Path: c:\xampp\htdocs\mocca-react\src\contexts\FormContext.js [the path to the file]
 *  - Date: 2021-11-3
 *  - Username: Andy (andhy@earworks.co.id)
 *  - Copyright: 2021 Earworks Consulting
 *  - Contact: contact@earworks.co.id
 */

import React, { useCallback, useState } from "react";

const defaultFormData = {
    cashAdvance: false,
    claim: false,
};
const FormContext = React.createContext({
    defaultFormData,
    store: () => {},
    remove: () => {},
});

const FormContextProvider = (props) => {
    const [formData, setFormData] = useState(defaultFormData);

    const store = useCallback((form, data) => {
        if (form === "cashAdvance") {
            setFormData((prev) => {
                return {
                    ...prev,
                    cashAdvance: data,
                };
            });
        }
        if (form === "claim") {
            setFormData((prev) => {
                return {
                    ...prev,
                    claim: data,
                };
            });
        }
    }, []);

    const remove = useCallback((form) => {
        if (form === "cashAdvance") {
            setFormData((prev) => {
                return {
                    ...prev,
                    cashAdvance: defaultFormData.cashAdvance,
                };
            });
        }
        if (form === "claim") {
            setFormData((prev) => {
                return {
                    ...prev,
                    claim: defaultFormData.claim,
                };
            });
        }
    }, []);

    return (
        <FormContext.Provider
            value={{ formData: formData, store: store, remove: remove }}
        >
            {props.children}
        </FormContext.Provider>
    );
};
export default FormContext;
export { FormContextProvider };
