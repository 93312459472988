/* 
 *  - File: index.js
 *  - Path: c:\xampp\htdocs\mocca-react\src\pages\errors\NotFound\index.js [the path to the file]
 *  - Date: 2021-10-1
 *  - Username: Andy (andhy@earworks.co.id)
 *  - Copyright: 2021 Earworks Consulting
 *  - Contact: contact@earworks.co.id
 */

import React from "react";
// import s from './NotFound.module.css';

const NotFound = (props) => {
  return <div>Not Found</div>;
};

export default NotFound;
