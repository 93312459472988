/* 
 *  - File: useInput.js
 *  - Path: c:\xampp\htdocs\mocca-react\src\hooks\useInput.js [the path to the file]
 *  - Date: 2021-10-1
 *  - Username: Andy (andhy@earworks.co.id)
 *  - Copyright: 2021 Earworks Consulting
 *  - Contact: contact@earworks.co.id
 */

import { useReducer } from "react";

const initialState = { touched: false, value: "" };
const initialError = { status: true, msg: null };

const rulesLibrary = {
    required: (value) => {
        if (!value) {
            return { status: false, msg: "This field is required" };
        } else {
            return initialError;
        }
    },
    validEmail: (value) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if ( re.test(String(value).toLowerCase()) === false) {
            return { status: false, msg: "Not valid email address" };
        } else {
            return initialError;
        }
    },
};

const inputStateReducer = (state, action) => {
    // touched base on previous state is false
    if (action.type === "INPUT") {
        return { value: action.value, touched: state.touched };
    }
    // touched is true when user leaving the input
    // and run validation
    if (action.type === "BLUR") {
        return { touched: true, value: state.value };
    }
    // set back to initial state when reset
    if (action.type === "RESET") {
        return initialState;
    }
    return initialState;
};
const useInput = (rules, customState) => {
    const [inputState, dispatch] = useReducer(
        inputStateReducer,
        typeof customState !== "undefined"
            ? { touched: false, value: customState.value }
            : initialState,
    );

    // validate value here
    let valueIsValid;
    if (typeof rules === "string" && rules) {
        rules.split("|").forEach((item) => {
            const result = rulesLibrary[item](inputState.value);
            valueIsValid = result;
        });
    } else {
        if (typeof rules === "function") {
            // otherwise assume that value passed to rules is function
            valueIsValid = rules(inputState.value);
        } else {
            valueIsValid = initialError;
        }
    }
    // validate result
    let hasError;
    if (
        typeof customState !== "undefined" &&
        typeof customState.error !== "undefined" &&
        inputState.touched === false
    ) {
        valueIsValid = { status: false, msg: customState.error };
        hasError = true;
    } else {
        hasError = !valueIsValid.status && inputState.touched;
    }

    // define event handler for input
    const changeHandler = (event) => {
        dispatch({ type: "INPUT", value: event.target.value });
    };
    const blurHandler = (event) => {
        dispatch({ type: "BLUR" });
    };
    const reset = (event) => {
        dispatch({ type: "RESET" });
    };

    const touch = (event) => {
        if (inputState.touched !== true) {
            // same as blur event
            dispatch({ type: "BLUR" });
        }
    };
    return {
        value: inputState.value,
        isTouch: inputState.touched,
        error: {
            status: hasError,
            msg: valueIsValid.msg,
        },
        onChange: changeHandler,
        onBlur: blurHandler,
        reset: reset,
        touch: touch,
        dispatch:dispatch
    };
};
export default useInput;
