/* 
 *  - File: index.js
 *  - Path: c:\xampp\htdocs\mocca-react\src\fragments\Modal\index.js [the path to the file]
 *  - Date: 2021-10-1
 *  - Username: Andy (andhy@earworks.co.id)
 *  - Copyright: 2021 Earworks Consulting
 *  - Contact: contact@earworks.co.id
 */

import React from "react";
import ReactDOM from "react-dom";
import s from "./Modal.module.css";
import Overlay from "../Overlay";
import { BiX } from "react-icons/bi";
import ButtonBorder from "../../fragments/ButtonBorder";
import { CSSTransition } from "react-transition-group";

const Modal = (props) => {
  const nodeRef = React.useRef(null); // fix deprecated findDOMNode while using React.StrictMode
  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={nodeRef}
      in={props.modalOpen}
      unmountOnExit
      timeout={300}
      classNames={{
        // appear: 'my-appear',
        // appearActive: 'my-active-appear',
        // appearDone: 'my-done-appear',
        // enter: s.caopen,
        // enterActive: s.caopen,
        enterDone: s.caopen,
        exit: s.caclose,
        // exitActive: s.caclose,
        // exitDone: s.caclose
      }}
    >
      <div className={s.ca} ref={nodeRef}>
        <Overlay onClick={props.closeHandler} />
        <div className={s.cacontent}>
          <button className={s.caclosebtn} onClick={props.closeHandler}>
            <BiX />
          </button>
          <ModalHeader title={props.title} smallTitle={props.smallTitle} />
          <div className={s.cabody}>{props.children}</div>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("ca-modal")
  );
};

const ModalHeader = (props) => {
  const title = props.title ? (
    <span className={s.catitle}>{props.title}</span>
  ) : null;
  const smallTitle = props.smallTitle ? (
    <span className={s.catitlesmall}>{props.smallTitle}</span>
  ) : null;
  if (title || smallTitle) {
    return (
      <div className={s.caheader}>
        {title}
        {smallTitle}
      </div>
    );
  } else {
    return null;
  }
};
const ModalFooter = (props) => {
  return <div className={s.cafooter}>{props.children}</div>;
};
const ModalButton = (props) => {
  // i will take care for some property
  const properties = {
    ...props,
    theme: props.theme ?? "purple",
    onClick: props.onClick,
  };
  return <ButtonBorder {...properties}>{props.children}</ButtonBorder>;
};

export default Modal;
export { ModalFooter, ModalButton };
