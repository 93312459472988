/* 
 *  - File: index.js
 *  - Path: c:\xampp\htdocs\mocca-react\src\fragments\Overlay\index.js [the path to the file]
 *  - Date: 2021-10-1
 *  - Username: Andy (andhy@earworks.co.id)
 *  - Copyright: 2021 Earworks Consulting
 *  - Contact: contact@earworks.co.id
 */

import React from "react";
import s from "./Overlay.module.css";

/**
 * 
 * @param {*} props 
 * @returns 
 */
const Overlay = (props) => {
  return (
    <div className={s.ca} onClick={props.onClick}></div>
  );
};
export default Overlay;
