/* 
 *  - File: index.js
 *  - Path: c:\xampp\htdocs\mocca-react\src\fragments\Loading\index.js [the path to the file]
 *  - Date: 2021-10-1
 *  - Username: Andy (andhy@earworks.co.id)
 *  - Copyright: 2021 Earworks Consulting
 *  - Contact: contact@earworks.co.id
 */

import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import s from "./Loading.module.css";
import Overlay from "../Overlay";
import { Image } from "..";
import imgSpin from "../../media/images/spin.gif";
/**
 *
 * @param {*} props
 * @returns
 */
const Loading = (props) => {
  const nodeRef = React.useRef(null); // fix deprecated findDOMNode while using React.StrictMode

  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={nodeRef}
      in={props.isOpen}
      unmountOnExit
      timeout={{enter:0, appear:0, exit:300}}
      classNames={{
        // appear: 'my-appear',
        // appearActive: 'my-active-appear',
        // appearDone: 'my-done-appear',
        enter: s.caopen,
        enterActive: s.caopen,
        enterDone: s.caopen,
        exit: s.caclose,
        exitActive: s.caclose,
        exitDone: s.caclose
      }}
    >
      <div className={s.cawrapper} ref={nodeRef}>
        <Overlay />

        <div className={s.ca}>
          <Image src={imgSpin} className={s.ca_gif} />
          <span className={s.ca_text}>Loading</span>
        </div>
      </div>
    </CSSTransition>,
    document.getElementById("ca-loading")
  );
};

export default Loading;
