/* 
 *  - File: index.js
 *  - Path: c:\xampp\htdocs\mocca-react\src\fragments\Toast\index.js [the path to the file]
 *  - Date: 2021-10-1
 *  - Username: Andy (andhy@earworks.co.id)
 *  - Copyright: 2021 Earworks Consulting
 *  - Contact: contact@earworks.co.id
 */

import React from 'react';
import ReactDOM from "react-dom";
import s from "./Toast.module.css";
import { CSSTransition } from "react-transition-group";
import { BiX } from "react-icons/bi";

const Toast = (props) => {
  const nodeRef = React.useRef(null); // fix deprecated findDOMNode while using React.StrictMode
  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={nodeRef}
      in={props.toastOpen}
      unmountOnExit
      timeout={{ enter: 0, exit: 300 }}
      classNames={{
        // appear: 'my-appear',
        // appearActive: 'my-active-appear',
        // appearDone: 'my-done-appear',
        // enter: s.caopen,
        // enterActive: s.caopen,
        enterDone: s.caopen,
        leave: s.caclose,
        // exitActive: s.caclose,
        // exitDone: s.caclose
      }}
    >
      <div className={`${s.ca} ${s[props.theme ?? ""]}`} ref={nodeRef}>
        <div className={s.cacontent}>{props.children}</div>
        <button className={s.caclosebtn} onClick={props.toastCloseHandler}>
          <BiX />
        </button>
      </div>
    </CSSTransition>,
    document.getElementById("ca-toast")
  );
};

export default Toast;
