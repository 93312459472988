/* 
 *  - File: index.js
 *  - Path: c:\xampp\htdocs\mocca-react\src\pages\index.js [the path to the file]
 *  - Date: 2021-10-1
 *  - Username: Andy (andhy@earworks.co.id)
 *  - Copyright: 2021 Earworks Consulting
 *  - Contact: contact@earworks.co.id
 */

import React from 'react';
export const Login = React.lazy(() => import('./Login'));
export const Main = React.lazy(() => import('./Main'));
export const CashAdvance = React.lazy(() => import('./CashAdvance'));
export const CashAdvanceCreate = React.lazy(() => import('./CashAdvance/Create'));
export const CashAdvanceView = React.lazy(() => import('./CashAdvance/View'));
export const CashAdvanceUpdate = React.lazy(() => import('./CashAdvance/Update'));
export const Claim = React.lazy(() => import('./Claim'));
export const ClaimCreate = React.lazy(() => import('./Claim/Create'));
export const ClaimView = React.lazy(() => import('./Claim/View'));
export const ClaimUpdate = React.lazy(() => import('./Claim/Update'));
export const ClaimReceipt = React.lazy(() => import('./ClaimReceipt'));
export const ClaimReceiptCreate = React.lazy(() => import('./ClaimReceipt/Create'));
// export const ClaimReceiptView = React.lazy(() => import('./ClaimReceipt/View')); // gk ada api nya
// export const ClaimReceiptUpdate = React.lazy(() => import('./ClaimReceipt/Update')); // gk ada api nya
export const Notification = React.lazy(() => import('./Notification'));
export const NeedAction = React.lazy(() => import('./NeedAction'));
export const Gasoline = React.lazy(() => import('./Gasoline'));
export const Profile = React.lazy(() => import('./Profile'));
export const Travel = React.lazy(() => import('./Travel'));
