/* 
 *  - File: useModal.js
 *  - Path: c:\xampp\htdocs\mocca-react\src\hooks\useModal.js [the path to the file]
 *  - Date: 2021-10-1
 *  - Username: Andy (andhy@earworks.co.id)
 *  - Copyright: 2021 Earworks Consulting
 *  - Contact: contact@earworks.co.id
 */

import React, { useState } from "react";
import Modal from "../fragments/Modal";
const useModal = () => {
  const [modalState, setModalState] = useState({
    isOpen: false,
    title: "",
    smallTitle: "",
    body: "",
  });
  const openHandler = (props) => {
    setModalState((prev) => {
      return {
        ...prev,
        isOpen: props.isOpen ?? false,
        title: props.title ?? "",
        smallTitle: props.smallTitle ?? "",
        body: props.body ?? "",
      };
    });
  };
  const closeHandler = () => {
    setModalState((prev) => {
      return {
        ...prev,
        isOpen: false,
        title: "",
        smallTitle: "",
        body: "",
      };
    });
  };
  const modal = (
    <Modal
      modalOpen={modalState.isOpen}
      closeHandler={closeHandler}
      title={modalState.title}
      smallTitle={modalState.smallTitle}
    >
      {modalState.body}
    </Modal>
  );
  return [modal, openHandler, closeHandler, modalState];
};

export default useModal;
