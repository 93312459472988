/* 
 *  - File: useLoading.js
 *  - Path: c:\xampp\htdocs\mocca-react\src\hooks\useLoading.js [the path to the file]
 *  - Date: 2021-10-1
 *  - Username: Andy (andhy@earworks.co.id)
 *  - Copyright: 2021 Earworks Consulting
 *  - Contact: contact@earworks.co.id
 */

import React, { useState, useEffect } from "react";
import { Loading } from "../fragments";
/**
 * [PENDING]
 * too many re-execution for one page
 * @returns 
 */
const useLoading = () => {
  const [loadingOpen, setLoadingOpen] = useState(false);
  useEffect(() => {
    setLoadingOpen(true);
    // let timer = setTimeout(() => {
    //   setLoadingOpen(false);
    // }, 500);
    // return () => {
    //   clearTimeout(timer);
    // };
  }, []);
  const openHandler = () => {
    setLoadingOpen(true);
  };
  const closeHandler = () => {
    setLoadingOpen(false);
  };
  const loading = <Loading isOpen={loadingOpen} theme="black" />;
  return [loading, openHandler, closeHandler];
};
export default useLoading;
